import React from 'react'

const Calculators = () => {
    return (
        <div>
            <h1>Crypto Power Keys Calculators</h1>
            <ul>
                Calculators
                Percentage of Tokens
            </ul>
            <ul>
                Converters
                BNB to USD
            </ul>
        </div>
    )
}

export default Calculators
