import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Main from './components/main/Main'
import Home from './components/home/Home'
import Navbar from './components/navbar/Navbar'
import Sidebar from './components/sidebar/Sidebar'
import 'bootstrap/dist/css/bootstrap.min.css'
import CheckMyCrypto from './components/checkmycrypto/CheckMyCrypto'
import Coming from './components/checkmycrypto/Coming'
import Tokenomics from './components/tokenomics/Tokenomics'
import Calculators from './components/calculators/Calculators'



const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  

  const openSidebar = () => {
    setSidebarOpen(true)
  }

  const closeSidebar = () => {
    setSidebarOpen(false)
  }

  return (
    <Router>
      <div className="container">
        <Navbar sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/check" component={CheckMyCrypto} />
          <Route path="/coming" component={Coming} />         
          <Route path="/tokenomics" component={Tokenomics} />         
          <Route path="/calculators" component={Calculators} />         
        </Switch>
        <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
      </div>
    </Router>
  );
}

export default App;
