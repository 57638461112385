import React, { useState, Fragment } from 'react';
import "./CheckNew.css"
import { ethers } from 'ethers'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

const CheckMyCrypto = () => {
    const [walletAddress, setWalletAddress] = useState('')
    const apiKey = 'HVX1N4JHDJ8VVKCUW362EWHBJ5RYXQUZ68'
    const [tokenList, setTokenList] = useState([])
    let tokenMap = new Map()

    let tokenListObjArray = []

    // setaddress function being used to pull in the wallet address from the input
    const setAddress = e => {
        let myWallet = e.target.value
        setWalletAddress(e.target.value)
        console.log(e.target.value)
        console.log("wallet address: " + myWallet)
        getWalletTransactions(myWallet)
    }


    // get transactions from the wallet
    async function getWalletTransactions(walletAddress) {
        const url =
            `https://api.bscscan.com/api?module=account&action=tokentx&address=${walletAddress}&sort=asc&apikey=${apiKey}`
        const response = await fetch(url);
        const data = await response.json();
        console.log(data.result);
        setTokenList(data.result)
    }


    // non - react method filtering the token list and adding it to a hashmap
    console.log("There should be 60 or 61 tokens in this list - tokenList " + tokenList.length)
    if (tokenList.length > 0) {
        Array.prototype.forEach.call(tokenList, element => {
            let contractAddress = element.contractAddress
            let tokenSymbol = element.tokenSymbol
            console.log("Contract Address from tokenList: " + contractAddress + "   tokenSymbol: " + tokenSymbol)
            if (tokenMap.has(contractAddress)) {
            } else {
                tokenMap.set(contractAddress, tokenSymbol)
            }
        });
    }

    // non-react method that is getting the transaction
    if (tokenList.length > 0) {
        Array.prototype.forEach.call(tokenList, element => {
            getTransactionCost(element.hash)
        });
    }


    // Binance SmartChain Mainnet
    async function getTransactionCost(transactionHash) {
        const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org:443');
        const transaction = await provider.getTransaction(transactionHash);
        console.log(transaction.value._hex);
        let hexToDecimal = Number(transaction.value._hex);
        console.log(hexToDecimal)
        appendTokenList(transactionHash, hexToDecimal)
    }

    // grab the tranactionCost from the blockchain and add it to the tokenList
    function appendTokenList(hash, hexToDecimal) {
        tokenList.forEach(element => {
            if (hash === element.hash) {
                let convertCost = calculateCost(hexToDecimal)
                element.transactionCost = convertCost
                console.log("transaction cost" + element.transactionCost)
            }
        });
    }

   
    if (tokenMap.size > 0) {
        console.log("tokenMap Size is: " + tokenMap.size)
        for (const [key, value] of tokenMap) {
            var tokenObj = {}
            tokenObj.contract = key
            tokenObj.tokens = []
            let tokenSymbol = value
            // Instead of pushing the value which is the token symbol, we are just pushing and empty array to for later
            console.log("Contract Address from tokenMap: " + tokenObj.contract + "   tokenSymbol: " + tokenSymbol)
            console.log("This should be the empty array going into tokenlistobjArray" + tokenObj.tokens)
            tokenListObjArray.push(tokenObj);
        }

        console.log("tokenListObjArray Lenth element0: " + tokenListObjArray.length)
    }


    // Add Tokens from TokenList to tokenListObjArray
    if (tokenList.length > 0) {
        Array.prototype.forEach.call(tokenListObjArray, element => {
            console.log("tokenListObjArray element - Contract address" + element.contract)
            let contract = element.contract
            let tokensArray = element.tokens
            for (var i = 0; i < tokenList.length; i++) {
                // console.log("tokenList check: " + tokenList[i].contractAddress)
                let tokenContract = tokenList[i].contractAddress
                let tokenTransaction = tokenList[i]
                // console.log("tokenTransaction check: " + tokenTransaction)    
                if (contract === tokenContract) {
                    console.log("Token Transaction" + tokenTransaction)
                    tokensArray.push(tokenTransaction)
                }
            }
        });
        console.log("Final Token List" + tokenListObjArray)
    }


    function setDate(dateVar) {
        if (dateVar) {
            let totalTime = dateVar + "000"
            console.log("total time: " + totalTime)
            let timeStamp = parseInt(totalTime)
            console.log("dateVar" + dateVar)
            console.log("timestamp" + dateVar)
            let currentTimestamp = Date.now()
            console.log("current timestamp" + currentTimestamp)
            let dateObj = new Date(timeStamp)
            let month = dateObj.getMonth() + 1;
            console.log(month)
            let year = dateObj.getFullYear()
            console.log(year)
            let date = dateObj.getDate()
            console.log(date)
            return `${month}/${date}/${year}`
        }
    }

    function calculateCost(tokenCost) {
        console.log("token cost" + tokenCost)
        let tokensBnb = parseFloat(tokenCost / 20000000000000000000)
        if (tokensBnb === 0) {
            return "not available"
        }
        return tokensBnb
    }

    function totalTokens(decimals, tokenNum) {
        if (tokenNum) {
            console.log("decimals" + decimals)
            console.log("token num" + tokenNum)
            let convertDecimal = parseInt(decimals)
            console.log("decimals" + convertDecimal)
            if (tokenNum.length > 0) {
                let result = tokenNum.substring(0, tokenNum.length - convertDecimal)
                console.log("converted token count" + result)
                if (result <= 0) {
                    return parseInt(tokenNum)
                }
                return parseInt(result)
            }
        }
    }

    return (
        <main id='main'>
            <div className='main__container'>
                <h1>Check My Crypto</h1>
                <p>View itemized BSC transactions</p>
                <div>
                    <input className="form-control" type="text" placeholder="Enter Wallet Address" onChange={setAddress} />
                </div>
                {tokenListObjArray.length > 0 &&
                    <table className="table-condensed">
                        <thead>
                            <tr>
                                <th> Token Contract</th>

                                <th>
                                    Token Name
                                </th>
                                <th>
                                    Token Symbol
                                </th>
                                <th>
                                    Date
                                </th>
                                <th>
                                    Hash
                                </th>

                                <th>
                                    Cumulative Gas Used
                                </th>
                                <th>
                                    From
                                </th>
                                <th>
                                    To
                                </th>

                                <th>
                                    Total Tokens
                                </th>
                                {/* 
                            <th>
                                Token Cost in BNB
                            </th>
                            */}
                            </tr>
                        </thead>
                        <tbody>
                            {tokenListObjArray.map((token, i) => {
                                return (
                                    <Fragment>
                                        <tr key={i}>
                                            <td className="cell">
                                                {token.contract}
                                            </td>

                                            <td>

                                            </td>
                                            <td>

                                            </td>
                                            <td>

                                            </td>

                                            <td>

                                            </td>
                                            <td>

                                            </td>
                                            <td>

                                            </td>

                                            <td>

                                            </td>

                                            <td>

                                            </td>
                                            {/*
                                        <td>

                                        </td>
                                         */}
                                        </tr>
                                        {token.tokens.map((tokenItem, j) => {
                                            return (
                                                <Fragment>
                                                    <tr key={j}>
                                                        <td></td>

                                                        <td>
                                                            {tokenItem.tokenName}
                                                        </td>
                                                        <td>
                                                            {tokenItem.tokenSymbol}
                                                        </td>
                                                        <td>
                                                            {setDate(tokenItem.timeStamp)}
                                                        </td>
                                                        <td className="cell">
                                                            <a target="_blank" href={"https://www.bscscan.com/tx/" + tokenItem.hash} >{tokenItem.hash}</a>
                                                        </td>
                                                        <td>
                                                            {tokenItem.cumulativeGasUsed}
                                                        </td>
                                                        <td>
                                                            {tokenItem.from}
                                                        </td>
                                                        <td>
                                                            {tokenItem.to}
                                                        </td>
                                                        <td>
                                                            {totalTokens(tokenItem.tokenDecimal, tokenItem.value)}
                                                        </td>
                                                        {/*
                                                    <td>
                                                        {tokenItem.transactionCost}
                                                        
                                                    </td>
                                                     */}
                                                    </tr>
                                                </Fragment>
                                            )
                                        })}

                                    </Fragment>
                                )
                            })}

                        </tbody>
                    </table>
                }
            </div>
        </main>
    )
}

export default CheckMyCrypto