import "./SidebarNew.css"
import logo from "../../assets/cpk.png"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faTable, faBinoculars, faWindowClose, faHandshake, faGraduationCap, faCalculator, faFileCode } from '@fortawesome/free-solid-svg-icons'
import { FaBars } from 'react-icons/fa';


const Sidebar = ({ sidebarOpen, closeSidebar }) => {
    return (
        <div className={sidebarOpen ? "sidebar-responsive" : ""} id="sidebar">
            <div className="sidebar__title">
                <div className="sidebar__img">
                    <img src={logo} alt="logo" />
                    <h1>Crypto Power Keys</h1>                   
                </div>
                <span id="sidebarIcon" onClick={() => closeSidebar()}>
                    <FaBars />
                </span>
            </div>

            <div className="sidebar__menu">
                <div className="sidebar__link active_menu_link">
                    <span>
                        <FontAwesomeIcon icon={faHome} />
                    </span>
                    <Link to='/'>  Dashboard</Link>
                </div>
                <h2>APPS</h2>
                <div className="sidebar__link">
                    <span>
                        <FontAwesomeIcon icon={faTable} />
                    </span>
                    <Link to='/check'>  Check My Crypto</Link>
                </div>
                <div className="sidebar__link">
                    <span>
                        <FontAwesomeIcon icon={faBinoculars} />
                    </span>
                    <Link to='/coming'>  Premium Apps</Link>
                </div>
                {/*
                <div className="sidebar__link">
                    <span>
                        <FontAwesomeIcon icon={faWindowClose} />
                    </span>
                    <Link to='/'>  Get Out App</Link>
                </div>
                */}
                <div className="sidebar__link">
                    <span>
                        <FontAwesomeIcon icon={faGraduationCap} />
                    </span>
                    <Link to='/coming'>  Crypto Education</Link>
                </div>                
                <h2>TOOLS</h2>
                <div className="sidebar__link">
                    <span>
                        <FontAwesomeIcon icon={faCalculator} />
                    </span>
                    <Link to='/coming'>  Calculators</Link>
                </div>

                <h2>SERVICES</h2>
                <div className="sidebar__link">
                    <span>
                        <FontAwesomeIcon icon={faHandshake} />
                    </span>
                    <Link to='/coming'>  Contract Review</Link>
                </div>
                <div className="sidebar__link">
                    <span>
                        <FontAwesomeIcon icon={faFileCode} />
                    </span>
                    <Link to='/coming'>  Token Creation</Link>
                </div>
                <div className="sidebar__link">
                    <span>
                        <FontAwesomeIcon icon={faFileCode} />
                    </span>
                    <Link to='/coming'>  Developer APIs</Link>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
