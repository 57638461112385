import "./Main.css"
import logo from "../../assets/cpk.png"
import Chart from "../charts/Chart"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock, faCalculator, faUsers, faRocket } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const Main = () => {
    return (
        <main>
            <div className="main__container">
                <div className="main__title">

                </div>

                <h1 style={{ textAlign: "center" }}>Crypto Tools</h1>
                <div className="charts">
                    <div className="charts__left">
                        <div className="charts__left__title">
                            <div>
                            <h1>Crypto Power Keys</h1>
                                <br />
                                <p>
                                Crypto Power Keys will offer a suite of 
                                innovative utilities, applications and services
                                that will provide blockchain information on one 
                                platform. Any investor can utilize the basic 
                                functionality of the PKEY website, holders of 
                                PKEY will have access to future premium features.
                                </p>
                                <p>
                                Try our first App - <Link to='/check'>Check My Crypto</Link>
                                </p>
                                <p>
                                Stay tuned for Our Education and other resources to help you navigate
                                the Crypto space.
                                </p>
                                
                                
                                <br />
                               
                            </div>
                        </div>
                    </div>

                    <div className="charts__right">
                        <div className="charts__right__title">
                            <div>
                            <h1>Check My Crypto</h1>
                                <br />                              
                                <p>
                                Try out our first App - <Link to='/check'>Check My Crypto</Link>
                                </p>
                                <p>
                                Check My Crypto is an Application that allows you to see 
                                the individualized Token transactions of your Binance Smart Chain wallet.  
                                Just paste your wallet address into the app, and see all your token transactions.
                                </p>
                                <p>
                                Instead of searching for each individual transaction from within your wallet, 
                                see all transactions by Contract address using Check My Crypto.    
                                </p>
                                <p>
                                Future application updates will show the prices, allow sorting, 
                                show various views, and allow the downloading of transactions.  
                                We also plan to add features to buy and sell tokens from within the app and platform.
                                </p>
                                
                                <br />
                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </main >

    )
}

export default Main
