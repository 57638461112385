import React from 'react'
import "./CheckNew.css"

const Coming = () => {
    return (
        <main>
            <div>
                <h1>Coming Soon</h1>
            </div>
        </main>

    )
}

export default Coming
