import "./NavbarNew.css"
import avatar from "../../assets/logo.svg"
import { Link } from 'react-router-dom'
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaTelegram, FaMedium, FaBars, FaRedditAlien } from 'react-icons/fa';

const Navbar = ({ sidebarOpen, openSidebar }) => {
    return (
        <nav className="navbar">
            <div className="nav_icon" onClick={() => openSidebar()}>
                <span>
                    <FaBars />
                </span>
            </div>
            <div className="navbar__left">
                <Link to='/' className="active_link">Home</Link>
                           
                
                <a target="_blank" href="https://youtu.be/Oj4lEufsOj0 ">How It Works</a>                               
            </div>
            <div className="navbar_right_new">
                <a target="_blank" href="https://t.me/CryptoPowerKeys">
                    <span>
                        <FaTelegram />
                    </span>
                </a>
                <a target="_blank" href="https://t.me/CryptoPowerKeysAnnouncements">
                    <span>
                        <FaTelegram />
                    </span>
                </a>
                <a target="_blank" href="https://twitter.com/power_keys">
                    <span>
                        <FaTwitter />
                    </span>
                </a>
                <a target="_blank" href="https://medium.com/@cryptopowerkeys">
                    <span>
                        <FaMedium />
                    </span>
                </a>                       
                <a target="_blank" href="https://www.youtube.com/channel/UCG2MaPiu8_Pn2q7nwgsZwZA">
                    <span>
                        <FaYoutube />
                    </span>
                </a>
                <a target="_blank" href="https://www.reddit.com/user/cryptopowerkeys">
                    <span>
                        <FaRedditAlien />
                    </span>
                </a>            
            </div>
        </nav>
    )
}

export default Navbar
