import React from 'react'
import { Link } from 'react-router-dom'

const Tokenomics = () => {
    return (
        < main >
            <div className="main__container">
                <div className="main__title">

                </div>

                <h1 style={{ textAlign: "center" }}>Crypto Power Keys Tokenomics</h1>
                <div className="charts">
                    <div className="charts__left">
                        <div className="charts__left__title">
                            <div>                                
                                <h1>Total Supply - 1 Quadrillion PKEY</h1>
                                <br />
                                <h1>432 Trillion - DxSale (43%)</h1>
                                <ul>
                                    <li>250 Trillion - Presale*</li>
                                    <li>182 Trillion Pancake Swap Liquidity / DxSale Fees</li>
                                    <li>Liquidity Unlock Date - July 17, 2022</li>
                                                                      
                                </ul>                                    
                                <h1>20 Trillion - Charity Wallet (2%)</h1>
                                <ul>
                                    <li>Girl Develop It / Women Who Code</li>
                                    <li>STEM and Youth in Technology</li>
                                    <li>Community Driven Charitable Causes</li>                                   
                                </ul>    
                                
                                
                                <h1>120 Trillion - Operating Wallet (12%) </h1>
                                <ul>
                                    <li>Platform Fees, Development, Marketing,</li>
                                    <li>Legal, Public Relations, etc</li>                                   
                                </ul>               
                                
                                <h1>40 Trillion - Airdrop Wallet (4%) </h1>
                                <ul>
                                    <li>Locked for 3 months</li>                                                                      
                                </ul>  
                                
                                <h1>40 Trillion - Team Wallet (4%)</h1>
                                <ul>                                    
                                    <li>Locked for 6 months</li>                                                                      
                                </ul>                                                             
                               
                                <h1>348 Trillion - Future Liquidity (35%) </h1>
                                <ul>                                   
                                    <li>Released over 3 - 6 months</li>
                                    <li>Liquidity Locked for 1 Year</li>                                                                    
                                </ul>               
                                <br />                               
                               
                                
                                <h1>Presale Location: <a target="_blank" href="https://dxsale.app/app/v2_9/defipresale?saleID=134&chain=BSC">DxSale</a></h1>
                                <ul>                                   
                                    <li>* Unsold presale tokens will be burned</li>                                                                    
                                </ul>            
                                <br />

                            </div>
                        </div>
                    </div>

                    <div className="charts__right">
                        <div className="charts__right__title">
                            <div>
                                <h1>Crypto Power Keys (PKEY)</h1>
                                <br />
                                <h1>10% Transaction Fee</h1>
                                <ul>
                                    <li>5% Holders</li>
                                    <li>5% Liquidity</li>                                                                    
                                </ul>                                    
                                <p>
                                    Crypto Power Keys will offer a suite of
                                    innovative utilities, applications and services
                                    that will provide blockchain information on one
                                    platform. Any investor can utilize the basic
                                    functionality of the PKEY website, holders of
                                    PKEY will have access to future premium features.
                                </p>
                                <p>
                                    Check out our first App - <Link to='/check'>Check My Crypto</Link>
                                </p>
                                <p>
                                    Check My Crypto is an Application that allows you to see
                                    the individualized Token transactions of your Binance Smart Chain wallet.
                                    Just paste your wallet address into the app, and see all your token transactions.
                                </p>
                                <p>
                                    Instead of searching for each individual transaction from within your wallet,
                                    see all transactions by Contract address using Check My Crypto.
                                </p>
                                <p>
                                    Future application updates will show the prices, allow sorting,
                                    show various views, and allow the downloading of transactions.
                                    We also plan to add features to buy and sell tokens from within the app and platform.
                                </p>

                                <br />
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </main >
    )
}

export default Tokenomics
